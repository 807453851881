<template>
  <div :id="swiperId" class="swiper">
    <div class="swiper-wrapper">
      <slot name="slide-items"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    swiperId: {
      type: String,
      default: "gallery-images",
    },
    swiperOpts: {
      type: Object,
      default: () => {
        return {
          slidesPerView: 8,
          spaceBetween: 10,
          breakpoints: {
            350: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 50,
            },
            1500: {
              slidesPerView: 7,
              spaceBetween: 50,
            },
            1700: {
              slidesPerView: 7,
              spaceBetween: 50,
            },
             1900: {
              slidesPerView: 8,
              spaceBetween: 50,
            },
          },
        };
      },
    },
  },
  mounted() {
    new Swiper(`#${this.swiperId}`, this.swiperOpts);
  },
};
</script>

<style>
</style>