<template>
  <div>
    <div v-if="section && pin">
      <v-img
        contain
        class="grey darken-4 banner-pin"
        height="450"
        :src="loadImg(section.URLFOTO)"
      >
        <v-container class="h-100 d-flex flex-column justify-content-between">
          <v-row>
            <v-col cols="12" lg="12">
              <TitleSection
                class="pl-0"
                :title="titleReturn[lang]"
                noLogo
                btnBack
                smallText
                dark
              ></TitleSection>
            </v-col>
          </v-row>
          <div v-if="pin" class="title-pin-container">
            <h5 class="title-pin">{{ pin.DESCRIPCION }}</h5>
            <p class="subtitle-pin">{{ section.COLECCION }}</p>
          </div>
        </v-container>
      </v-img>

      <v-container class="pb-3">
        <v-row class="mt-2">
          <v-col cols="12" lg="12">
            <TitleSection :title="pin.DESCRIPCION" noLogo></TitleSection>
          </v-col>
          <v-col cols="12" lg="12">
            <p
              v-html="section.DESCRIPCIONLARGA || section.DESCRIPCIONCORTA"
            ></p>
          </v-col>
        </v-row>

        <v-row v-if="galleryType1 && galleryType1.length > 0">
          <v-col cols="12" lg="12">
            <TitleSection title="Fotos" noLogo></TitleSection>
          </v-col>

          <v-col cols="12" lg="12">
            <viewer :images="galleryType1" :options="viewerOpts">
              <Swiper swiperId="gallery-photos">
                <template v-slot:slide-items>
                  <div
                    class="swiper-slide"
                    v-for="(item, idx) in galleryType1"
                    :key="item.IDGALERIA + idx"
                  >
                    <v-card elevation="0" height="100">
                      <img
                        class="mouse-pointer w-100 h-100 object-cover"
                        :src="loadImg(item.URLMINIATURA)"
                        :srcset="loadImg(item.URLMINIATURA)"
                        alt=""
                      />
                    </v-card>
                  </div>
                </template>
              </Swiper>
            </viewer>
          </v-col>
        </v-row>
        <v-row v-if="galleryType2 && galleryType2.length > 0">
          <v-col cols="12" lg="12">
            <TitleSection title="360" noLogo></TitleSection>
          </v-col>

          <v-col cols="12" lg="12" md="12" sm="12">
            <Swiper swiperId="gallery-360">
              <template v-slot:slide-items>
                <div
                  class="swiper-slide"
                  v-for="(item, idx) in galleryType2"
                  :key="item.IDGALERIA + idx"
                >
                  <CardAbout
                    :section="item"
                    :href="{
                      name: 'Visor360Page',
                      params: { id360: item.IDGALERIA }
                    }"
                    heigthImage="80"
                    :labelBtn="{
                      en: 'SEE 360°',
                      es: 'VER 360°'
                    }"
                  ></CardAbout>
                </div>
              </template>
            </Swiper>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { imgService } from "@/libs/media";
import TitleSection from "@/components/titles/TitleSection";
import CardAbout from "./components/CardAbout.vue";
import Swiper from "@/components/Swiper";
import { mapGetters } from "vuex";

export default {
  components: {
    TitleSection,
    CardAbout,
    Swiper
  },
  data() {
    return {
      titleReturn: {
        en: "Go back",
        es: "Regresar"
      },
      viewerOpts: {
        button: true,
        navbar: true,
        title: false,
        toolbar: false,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: false,
        fullscreen: true
      },
      section: null,
      pin: null,
      galleryType1: null, // Fotos
      galleryType2: null // 360
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      accTools: "getAccesibilityTool"
    })
  },
  watch: {
    lang(oldval) {
      this.loadData();
    },
    pin() {
      this.accTools.restart(this.lang);
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const { section, pin } = this.$route.params;
        this.$store.commit("activeLoader", true);
        const collections = await this.$store.dispatch(
          "fetchConsultCollections",
          section
        );
        this.section = collections.find(item => item.IDPIN == pin);
        if (!this.section) return;
        this.pin = await this.$store.dispatch("fetchDataPIN", { idPin: pin, idColeccion: section });
        if (!pin) return;
        const gallery = await this.$store.dispatch("fetchPinGallery", { idPin: pin, idColeccion: section });
        this.galleryType1 = gallery.filter(item => item.IDTIPOGALERIA == "1");
        this.galleryType2 = gallery.filter(item => item.IDTIPOGALERIA == "2");
        this.$store.commit("activeLoader", false);
      } catch (err) {
        this.$store.commit("activeLoader", false);
      }
    },
    loadImg(file) {
      return imgService(file);
    },
    getBase64Image(url) {
      fetch(url, {
        method: "GET",
        redirect: "follow"
      })
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );
    },
    getAverageRGB(imgEl) {
      let blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement("canvas"),
        context = canvas.getContext && canvas.getContext("2d"),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

      if (!context) {
        return defaultRGB;
      }

      height = canvas.height =
        imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
      width = canvas.width =
        imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

      context.drawImage(imgEl, 0, 0);

      try {
        data = context.getImageData(0, 0, width, height);
      } catch (e) {
        /* security error, img on diff domain */
        return defaultRGB;
      }

      length = data.data.length;

      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }

      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);

      return rgb;
    }
  }
};
</script>

<style lang="scss">
.title-pin-container {
  display: flex;
  flex-flow: column nowrap;
  color: #fff;
  .title-pin {
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  .subtitle-pin {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-yellow);
    @media screen and (max-width: 600px) {
      font-size: 13.5px;
    }
  }
}
</style>
