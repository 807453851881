<template>
  <router-link class="link-card-about" :to="href">
    <v-card :loader-height="1" class="mx-auto" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img
        :height="heigthImage ? heigthImage : '250'"
        class="grey lighten-2 white--text align-end"
        gradient="to top right, rgba(0,0,0,.13), rgba(0,0,0,.3)"
        :src="loadImage(section.URLFOTO)"
        :lazy-src="loadImage(section.URLFOTO)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <div class="icon-container">
            <div class="view-more-title">
                <v-icon dark>arrow_right_alt</v-icon>
                <span>{{ labelBtn ? labelBtn[lang]: btnLabel[lang] }}</span>
            </div>
        </div>
        <v-card-title v-if="section.DESCRIPCION">
          <div class="title-card-about">
            {{ section.DESCRIPCION }}
          </div>
        </v-card-title>
      </v-img>
    </v-card>
  </router-link>
</template>

<script>
import { imgService } from "@/libs/media";
import { mapGetters } from 'vuex';
export default {
  props: {
    section: Object,
    href: Object,
    heigthImage: String,
    labelBtn: Object
  },
  data() {
      return {
          btnLabel: {
              en: "SEE MORE",
              es: "VER MÁS"
          }
      }
  },
  computed: {
      ...mapGetters({
          lang: "getLanguage"
      })
  },
  methods: {
    loadImage(img) {
      return imgService(img);
    },
  },
};
</script>

<style lang="scss">
.link-card-about {
  text-decoration: none !important;
  position: relative;
  .title-card-about {
    background: var(--color-blue);
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 8px;
    z-index: 10;
    @media screen and (max-width: 500px) {
        font-size: 12px;
    }
  }
  .icon-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 00, 0.3);
    transition: all ease-in-out 0.25s;
    display: flex;
    align-content: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
  }
  .view-more-title {
      color: var(--color-yellow);
      font-weight: 600;
      font-family: $primary-font;
      font-size: 13px;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: center;
      align-items: center;
  }
  .v-image__image {
    transition: all ease-in-out 0.25s;
  }

  &:hover {
    .v-image__image {
      transform: scale(1.1);
    }
    .icon-container {
      opacity: 1;
      visibility: visible;
    }
  }
}

.col-card-about {
  &:nth-child(2n + 1) {
    .title-card-about {
      background: var(--color-turquoise);
    }
  }
  &:nth-child(2n) {
    .title-card-about {
      background: var(--color-blue);
    }
  }
}
</style>